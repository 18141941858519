import axiosResource from '@/utils/axiosResource';

const state = {
  selectedCount: 0, // Track only the count of selected items
  selectionMode: false, // TO DELETE
  savedSelections: [],
};

const getters = {
  savedSelections: (state) => state.savedSelections,
  selectionMode: (state) => {
    return state.selectionMode;
  },
  selectedCount: (state) => state.selectedCount,
};

const mutations = {
  setSelectionMode(state, value) {
    state.selectionMode = value;
  },
  SET_SELECTED_COUNT(state, count) {
    state.selectedCount = count; // Set the selected count
  },
  SET_SELECTION_MODE(state, mode) {
    state.selectionMode = mode;
  },
  SET_SAVED_SELECTIONS(state, selections) {
    console.log(`-> commit SET_SAVED_SELECTIONS`);
    state.savedSelections = [...selections];
    // state.savedSelections = selections;
  },
  ADD_SAVED_SELECTION(state, selection) {
    state.savedSelections.push(selection);
  },
};

const actions = {
  fetchSavedSelections({ commit }) {
    console.log('fetchSavedSelections');
    return axiosResource
      .get('selection/saved')
      .then((response) => {
        console.log(response);
        commit('SET_SAVED_SELECTIONS', response.data.savedSelections);
      })
      .catch((error) => {
        console.error('Error fetching saved selections:', error);
      });
  },
  addSavedSelection({ commit }, selection) {
    commit('ADD_SAVED_SELECTION', selection);
  },
  updateSelectedCount({ commit }, count) {
    commit('SET_SELECTED_COUNT', count); // Update selected count based on provided value
  },
  updateSelectionMode({ commit }, mode) {
    commit('SET_SELECTION_MODE', mode);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
